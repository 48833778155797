.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar {
  border-bottom: 3px solid rgb(0, 132, 255);
  font-size: 20px;
}
.navbar-collapse {
  float: right;
}
#bgGreen {
  background-color: rgb(153,197,39);
}
#bgBlue {
  background-color: rgb(35,90,143);
}
#bgWhite{
  background-color:white;
}
#containerSpace{
  padding-top: 50px;
  padding-bottom: 50px;
}

#head{
  color:white;
  text-align: center;
}
#body{
  font-family: serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
